
  import { defineComponent, ref, computed } from "vue"
  export default defineComponent({
    name: 'ActionTitle',
    props: {
      title: {
        type: String,
      },
      pretitle: {
        type: String,
        default: 'Übersicht'
      },
      actions: {
        type: Array,
        default: () => []
      }/*,
      settings: {
        type: Object,
        default: () => ({})
      },*/
    },
    setup(props) {
      /*const showButtons = computed(() => {
        if (!props.settings.buttonThreshold) return true
        return props.settings.buttonThreshold <= props.actions.length
      })*/



      return {
        //showButtons
      }
    }
  })
