
  import { defineComponent, computed } from "vue"
  import { shortDate } from "@/utils/helper"
  import { fullName } from "@/utils/dataExtractors"
  import { ResourceObject } from '@/models/jsonapi'
  import { Lead } from "@/models/leads";
  import moment from 'moment'

  interface Props { lead: ResourceObject<string, Lead> }

  export default defineComponent({
    name: 'InternalLeadKeyData',
    props: {
      lead: {
        type: Object,
        required: true
      },
    },
    setup(props: Props) {
      const present = (val) => val != null && val != undefined
      const la = computed(()=>props.lead.attributes)
      const sellerName = computed(() => {
        if (props.lead.attributes?.seller){
          return fullName(props.lead.attributes.seller)
        } else {return 'Nicht zugewiesen'}
      })
      return {
        shortDate,
        present,
        sellerName,
        la,
        moment
      }
    }
  })
